<template>
  <div class="working-schedule">
    <b-row align-h="between" align-v="center" class="mb-1">
      <b-col>
        <label> {{ $t(field.label) }} </label>
      </b-col>
      <b-col cols="auto">
        <b-button
          variant="outline-primary"
          pill
          class="btn-plus"
          @click="addNewLine"
        >
          <feather-icon icon="PlusIcon" />
        </b-button>
      </b-col>
    </b-row>

    <div
      v-for="(line, idx) in lines"
      :key="`result-${idx}-${lines.length}`"
      class="line"
      :class="{
        'mb-2': idx < lines.length - 1,
      }"
    >
      <b-row align-h="end" v-if="lines.length > 1">
        <b-col cols="auto">
          <b-button
            variant="outline-danger"
            pill
            class="btn-close"
            @click="removeLine(idx)"
          >
            <feather-icon icon="XIcon" />
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            :vid="`productId-${idx}`"
            name="field.product"
            rules="required"
          >
            <b-form-group>
              <label> {{ $t("field.product") }} </label>
              <n-async-single-select
                v-model="line.productId"
                :ref="`productId-${idx}`"
                :name="`productId-${idx}`"
                :init-options="line.productId"
                repository="product"
                selection-key="id"
                selection-label="label"
                localization
                :class="errors.length > 0 ? 'is-invalid' : null"
                @change="input"
              >
              </n-async-single-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            :vid="`quantity-${idx}`"
            name="field.quantity"
            rules="required|integer"
          >
            <b-form-group>
              <label> {{ $t("field.quantity") }} </label>
              <n-currency-input
                v-model="line.quantity"
                :ref="`quantity-${idx}`"
                :name="`quantity-${idx}`"
                :class="errors.length > 0 ? 'is-invalid' : null"
                suffix="CTN"
                @change="input"
              >
              </n-currency-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="auto">
          <b-form-checkbox
            v-model="line.isCalculateVolume"
            :ref="`isCalculateVolume-${idx}`"
            :name="`isCalculateVolume-${idx}`"
            :value="true"
            :unchecked-value="false"
          >
            {{ $t("field.calculateVolume") }}
          </b-form-checkbox>
        </b-col>
      </b-row>
    </div>
    <b-row class="mt-1">
      <b-col class="text-right">
        <b-button variant="outline-primary" @click="addNewLine">
          <feather-icon icon="PlusIcon" />
          {{ $t("button.addResult") }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BRow,
  BCol,
  BLink,
  BButton,
} from "bootstrap-vue";
import NCurrencyInput from "@/components/NCurrencyInput";
import NAsyncSingleSelect from "@/components/NAsyncSingleSelect";

export default {
  components: {
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BRow,
    BCol,
    BLink,
    BButton,

    NCurrencyInput,
    NAsyncSingleSelect,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    field: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      lines: [],
    };
  },
  mounted() {
    this.lines = [...this.value];
  },
  methods: {
    addNewLine() {
      this.lines.push({
        productId: null,
        quantity: null,
        isCalculateVolume: false,
      });
      this.input();
    },
    removeLine(idx) {
      this.lines.splice(idx, 1);
      this.input();
    },
    input() {
      this.$emit("input", this.lines);
    },
  },
};
</script>
