<template>
  <div class="working-schedule">
    <b-row align-h="between" align-v="center" class="mb-1">
      <b-col>
        <label> {{ $t(field.label) }} </label>
      </b-col>
      <b-col cols="auto">
        <b-button
          variant="outline-primary"
          pill
          class="btn-plus"
          @click="addNewLine"
        >
          <feather-icon icon="PlusIcon" />
        </b-button>
      </b-col>
    </b-row>

    <div
      v-for="(line, idx) in lines"
      :key="`condition-${idx}-${lines.length}`"
      class="line"
      :class="{
        'mb-2': idx < lines.length - 1,
      }"
    >
      <b-row align-h="end" v-if="lines.length > 1">
        <b-col cols="auto">
          <b-button
            variant="outline-danger"
            pill
            class="btn-close"
            @click="removeLine(idx)"
          >
            <feather-icon icon="XIcon" />
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <validation-provider
            #default="{ errors }"
            :vid="`productId-${idx}`"
            name="field.product"
            rules="required"
          >
            <b-form-group>
              <label> {{ $t("field.product") }} </label>
              <n-async-single-select
                v-model="line.productId"
                :ref="`productId-${idx}`"
                :name="`productId-${idx}`"
                :init-options="line.productId"
                repository="product"
                selection-key="id"
                selection-label="label"
                localization
                :class="errors.length > 0 ? 'is-invalid' : null"
                @change="input"
              >
              </n-async-single-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="4">
          <validation-provider
            #default="{ errors }"
            :vid="`minQuantity-${idx}`"
            name="field.minQuantity"
            rules="required|integer"
          >
            <b-form-group>
              <label> {{ $t("field.minQuantity") }} </label>
              <n-currency-input
                v-model="line.minQuantity"
                :ref="`minQuantity-${idx}`"
                :name="`minQuantity-${idx}`"
                :class="errors.length > 0 ? 'is-invalid' : null"
                suffix="CTN"
                @change="input"
              >
              </n-currency-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="4">
          <validation-provider
            #default="{ errors }"
            :vid="`maxQuantity-${idx}`"
            name="field.maxQuantity"
            rules="required|integer"
          >
            <b-form-group>
              <label> {{ $t("field.maxQuantity") }} </label>
              <n-currency-input
                v-model="line.maxQuantity"
                :ref="`maxQuantity-${idx}`"
                :name="`maxQuantity-${idx}`"
                :class="errors.length > 0 ? 'is-invalid' : null"
                suffix="CTN"
                @change="input"
              >
              </n-currency-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
    </div>
    <b-row class="mt-1">
      <b-col class="text-right">
        <b-button variant="outline-primary" @click="addNewLine">
          <feather-icon icon="PlusIcon" />
          {{ $t("button.addCondition") }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BLink,
  BButton,
} from "bootstrap-vue";
import NCurrencyInput from "@/components/NCurrencyInput";
import NAsyncSingleSelect from "@/components/NAsyncSingleSelect";

export default {
  components: {
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BLink,
    BButton,

    NCurrencyInput,
    NAsyncSingleSelect,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    field: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      lines: [],
    };
  },
  mounted() {
    this.lines = [...this.value];
  },
  methods: {
    addNewLine() {
      this.lines.push({
        productId: null,
        minQuantity: null,
        maxQuantity: null,
      });
      this.input();
    },
    removeLine(idx) {
      this.lines.splice(idx, 1);
      this.input();
    },
    input() {
      this.$emit("input", this.lines);
    },
  },
};
</script>
